import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter, map, mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GAService } from './services/ga.service';
import { ThemeService } from './services/theme.service';
import { MsalBroadcastService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'SAPI-UI';
  showHeader: boolean = false;
  gtmId = environment.gtmId;
  url = 'https://www.googletagmanager.com/ns.html?id=' + this.gtmId;
  eventSubscription: Subscription;
  spinnerColor: string = '#fff'

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private gaService: GAService,
    private themeService: ThemeService,
    private broadcastService: MsalBroadcastService
  ) {
    this.eventSubscription = this.themeService.getEvent().subscribe(() => {
      this.setThemes()
    });
  }

  ngOnInit(): void {
    this.setThemes();

    //gtm script
    var script = this.renderer.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','` + this.gtmId + `');`;
    this.renderer.appendChild(document.head, script);

    //tracking script
    script = this.renderer.createElement('script');
    script.type = 'module';
    script.innerHTML = `import * as tl from "` + environment.trackingUrl + `"
    tl.trackEvent('` + environment.trackingId + `',[]);
    window.trackingService = tl;`;
    this.renderer.appendChild(document.head, script);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      mergeMap(route => route.data)
    ).subscribe((data: any) => {
      if (data.stepId !== undefined) {
        this.gaService.sendGAevent(data.stepId, data.stepName, data.eventName);
      }
      this.trackPageViews();
    })

    //to check b2c signup status after redirected from b2c page
    if (window.location.href.indexOf('#state=') > -1) {
      sessionStorage.setItem("signupSuccess", "true");
    } else if (window.location.href.indexOf('#error=') > -1) {
      sessionStorage.setItem("signupSuccess", "false");
    }


  }

  trackPageViews() {
    const trackingService = (window as any).trackingService;
    console.log('trackingservice', trackingService);
    if (trackingService) {
      trackingService.trackPageViews(environment.trackingId);
    } else {
      setTimeout(() => {
        this.trackPageViews();
      }, 100);
    }
  }

  setThemes() {
    let styleName = sessionStorage.getItem('brandName');
    if (styleName) {
      if(styleName == 'scs'){
        this.showHeader = false;
      }else{
        this.showHeader = true;
      }
      styleName == 'scs' ? this.spinnerColor = "#0075c9" : styleName == 'justmoney' ? this.spinnerColor = "#e59a2c" : this.spinnerColor = '#60a845';
      this.themeService.setTheme(styleName);
    }
  }
}

