import { LogLevel, Configuration } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.b2cConfig.auth.clientId,
        authority: environment.b2cConfig.auth.authority,
        knownAuthorities: [environment.b2cConfig.auth.authorityDomain],
        redirectUri: environment.b2cConfig.auth.redirectUri,
        postLogoutRedirectUri: environment.b2cConfig.auth.postLogoutRedirectUri,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: isIE,
    },
    system: {
        loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
                // console.log(message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}

export const protectedResources = {
    webApi: {
        endpoint: environment.webApiUrl,
        scopes: [environment.b2cConfig.resources.webApi.resourceScope],
    },
}
export const loginRequest = {
    scopes: [
        ...environment.b2cConfig.scopes.loginRequest
    ]
};