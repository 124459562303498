import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { Steps } from './core/enums/steps';

const routes: Routes = [
  {
    path: 'home',
    loadComponent: () => import('./pages/step0/step0.component').then(m => m.Step0Component)
  },
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'step1',
        loadComponent: () => import('./pages/steps/step1/step1.component').then(m => m.Step1Component),
        data: { stepId: 1, stepName: Steps[1], eventName: 'Lead journey started' }
      },
      {
        path: 'step2',
        loadComponent: () => import('./pages/steps/step2/step2.component').then(m => m.Step2Component),
        data: { stepId: 2, stepName: Steps[2], eventName: 'Lead to sign WP' }
      },
      {
        path: 'step5',
        loadComponent: () => import('./pages/steps/step5/step5.component').then(m => m.Step5Component),
        data: { stepId: 5, stepName: Steps[5], eventName: 'OTP started' }
      },
      {
        path: 'step6',
        loadComponent: () => import('./pages/steps/step6/step6.component').then(m => m.Step6Component),
        data: { stepId: 6, stepName: Steps[6], eventName: 'Lead sees quote' }
      },
      {
        path: 'step7',
        loadComponent: () => import('./pages/steps/step7/step7.component').then(m => m.Step7Component),
        data: { stepId: 7, stepName: Steps[7], eventName: 'Lead sees DC explanation' }
      },
      {
        path: 'step9',
        loadComponent: () => import('./pages/steps/step9/step9.component').then(m => m.Step9Component),
        data: { stepId: 9, stepName: Steps[9], eventName: 'Security Questions Set 1 started' }
      },
      {
        path: 'step10',
        loadComponent: () => import('./pages/steps/step10/step10.component').then(m => m.Step10Component),
        data: { stepId: 10, stepName: Steps[10], eventName: 'Security Questions Set 2 started' }
      },
      {
        path: 'step11',
        loadComponent: () => import('./pages/steps/step11/step11.component').then(m => m.Step11Component),
        data: { stepId: 11, stepName: Steps[11], eventName: 'Lead journey started' }
      },
      {
        path: 'step12',
        loadComponent: () => import('./pages/steps/step12/step12.component').then(m => m.Step12Component),
        data: { stepId: 12, stepName: Steps[12], eventName: 'Lead to enter SA ID' }
      },
      {
        path: 'step13',
        loadComponent: () => import('./pages/steps/step13/step13.component').then(m => m.Step13Component),
        data: { stepId: 13, stepName: Steps[13], eventName: 'To create DBC password' }
      },
      {
        path: 'step15',
        loadComponent: () => import('./pages/steps/step15/step15.component').then(m => m.Step15Component),
      },
      {
        path: 'step18',
        loadComponent: () => import('./pages/steps/step18/step18.component').then(m => m.Step18Component)
      },
      {
        path: 'step19',
        loadComponent: () => import('./pages/steps/step19/step19.component').then(m => m.Step19Component),
        data: { stepId: 19, stepName: Steps[19], eventName: 'Security Questions' }
      },

      {
        path: 'step20',
        loadComponent: () => import('./pages/steps/step20/step20.component').then(m => m.Step20Component),
        data: { stepId: 20, stepName: Steps[20], eventName: 'Referral started' }
      }
    ]
  },
  {
    path: 'exitstep',
    loadComponent: () => import('./pages/exit-step/exit-step.component').then(m => m.ExitStepComponent)
  },
  {
    path: 'process-failed',
    loadComponent: () => import('./pages/process-failed/process-failed.component').then(m => m.ProcessFailedComponent)
  },
  {
    path: 'session-timeout',
    loadComponent: () => import('./pages/session-timeout/session-timeout.component').then(m => m.SessionTimeoutComponent)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
