import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, finalize, map, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  env = environment;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService
  ) { }

  totalRequests = 0;
  completedRequests = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.includes('UserWaitingConfirmation')) {
      this.spinner.show();
      this.totalRequests++;
    }

    req = req.clone({
      headers: this.addExtraHeaders(req.headers),
    });

    return next
      .handle(req)
      .pipe(
        map((res) => {
          if (res instanceof HttpResponse) {
            if (res.status == 200) {

              if (res.body?.returnUrl?.startsWith('http')) {
                let url = new URL(res.body?.returnUrl);
                window.parent.postMessage(url.href, url.origin);
              }
              return res;
            }
            else if (res.status == 400) {
              this.router.navigateByUrl('/session-timeout');
            }
            else {
              this.router.navigateByUrl('/process-failed');
            }
          }
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 400) {
            this.router.navigateByUrl('/session-timeout');
          } else if (error.status == 401 && sessionStorage.getItem('accessToken') != null) {
            return this.handle401Error(req, next);
          } else if (req.url.indexOf("/api/Otp/") === -1) {
            this.router.navigateByUrl('/process-failed');
          }
          return throwError(() => error);
        })
      )
      .pipe(
        finalize(() => {
          if (!req.url.includes('UserWaitingConfirmation')) {
            this.completedRequests++;
          }

          if (this.completedRequests === this.totalRequests) {
            this.spinner.hide();
            this.completedRequests = 0;
            this.totalRequests = 0;
          }
        })
      );
  }

  private addExtraHeaders(headers: HttpHeaders): HttpHeaders {
    headers = headers.append('Cache-Control', 'no-cache');
    headers = headers.append('Pragma', 'no-cache');
    headers = headers.append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
    if (sessionStorage.getItem('accessToken')) {
      headers = headers.append('Authorization', `Bearer ${sessionStorage.getItem('accessToken')}`);
    }
    return headers;
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    const token = sessionStorage.getItem('accessToken');
    const refreshToken = sessionStorage.getItem('refreshToken');
    const credentials = { accessToken: token, refreshToken: refreshToken };
    return this.authService.refreshToken(credentials).pipe(
      // Refreshing Token
      switchMap((res: any) => {
        sessionStorage.setItem('accessToken', res.token);
        sessionStorage.setItem('refreshToken', res.refreshToken);

        request = request.clone({
          headers: request.headers.set('Authorization', `Bearer ${res.token}`)
        });
        return next.handle(request);
      }),
      catchError((err) => {
        if (request.url.indexOf("/api/Otp/") === -1) {
          sessionStorage.removeItem('accessToken');
          sessionStorage.removeItem('refreshToken');
          this.router.navigate(['/session-timeout']);
        }
        return throwError(() => err);
      })
    );
  }
}
