<!-- Google Tag Manager (noscript) -->
<noscript><iframe [src]="url | safeUrl" src="" height="0" width="0"
        style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->

<!-- Header HTML Start -->
<app-header *ngIf="showHeader"></app-header>
<!-- Header HTML End -->
<router-outlet></router-outlet>
<ngx-spinner id="spinner" bdColor="rgba(51, 51, 51, 0.8)" size="medium" [color]="spinnerColor"
    type="ball-circus"></ngx-spinner>

<!-- Footer HTML Start -->
<app-footer></app-footer>