import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: 'root'
})
export class GAService {

    constructor(private authService: AuthService) { }

    sendGAevent(stepId: number, stepName: string, eventName: string) {
        (window as any).dataLayer = (window as any).dataLayer || [];
        var event = {
            'event': eventName,
            'stepId': stepId.toString(),
            'stepName': stepName,
            'processId': sessionStorage.getItem('processId') ?? '',
            'processName': sessionStorage.getItem('processName') ?? '',
            'userId': sessionStorage.getItem('userId') ?? '',
            'visitId': this.authService.getVisitIdFromToken(),
            'product': sessionStorage.getItem('product') ?? '',
        };
        console.log(event);
        (window as any).dataLayer.push(event);
    }
}