import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class AuthService {
    env = environment;
    constructor(private httpClient: HttpClient, private jwtHelper: JwtHelperService) { }

    refreshToken(data: any) {
        return this.httpClient.post(this.env.webApiUrl + 'Token/Refresh', data);
    }

    getVisitIdFromToken() {
        var accessToken = sessionStorage.getItem("accessToken");
        if (accessToken) {
            return this.jwtHelper.decodeToken(accessToken).visitId;
        }
        return '';
    }
}