// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //webApiUrl: 'https://localhost:7185/api/',
  webApiUrl: 'https://app-sapi-api-dev-01.azurewebsites.net/api/',
  gtmId: 'GTM-P9G3QGC',
  b2cConfig: {
    auth: {
      "clientId": 'ee07b2c0-5b64-4cf9-ae5d-74de39914194',
      "authority": "https://debtbustersdev.b2clogin.com/debtbustersdev.onmicrosoft.com/b2c_1a_signup_custom",
      "authorityDomain": "https://debtbustersdev.b2clogin.com",
      "validateAuthority": true,
      "redirectUri": "https://app-sapi-ui-dev-01.azurewebsites.net/step18",
      "postLogoutRedirectUri": "https://app-sapi-ui-dev-01.azurewebsites.net/step18",
      // "redirectUri": "http://localhost:4200/step18",
      // "postLogoutRedirectUri": "http://localhost:4200/step18",
      "navigateToLoginRequestUrl": true
    },
    resources: {
      "webApi": {
        "resourceScope": "https://debtbustersdev.onmicrosoft.com/scs/user_impersonation", // here use the client id of the Web API you registered
      }
    },
    scopes: {
      "loginRequest": [
        "openid",
        "profile"
      ]
    }
  },
  trackingUrl: "https://devtrc.digitechlab.co.za/lib/minified-js/tracking-lib.js",
  trackingId: "004"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
