import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private eventSubject = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  emitEvent() {
    this.eventSubject.next();
  }

  getEvent() {
    return this.eventSubject.asObservable();
  }

  setTheme(styleName: string | undefined) {
    if (styleName === 'scs') {
      const body = this.document.getElementsByTagName('body')[0];
      body.classList.add("theme-scs");
      body.classList.remove("theme-jm");
    }
    else if (styleName === 'justmoney') {
      const body = this.document.getElementsByTagName('body')[0];
      body.classList.add("theme-jm");
      body.classList.remove("theme-scs");
    }
    else {
      const body = this.document.getElementsByTagName('body')[0];
      body.classList.remove("theme-scs");
      body.classList.remove("theme-jm");

    }
  }
}
