<header>
    <!-- Fixed navbar -->
    <nav class="navbar fixed-top py-0 " [ngClass]="{'bg-white': isScs}">
        <div
            class="container-fluid px-md-5 px-3 pt-md-3 pt-2 pb-md-0 pb-2 d-flex align-items-start justify-content-between">
            <a class="logo-hld navbar-brand p-0">

                <div class="header-logo">
                </div>
            </a>
            <i class="overlays position-absolute top-0 end-0">
                <div class="header-overlays">
                </div>
            </i>
        </div>
    </nav>

</header>