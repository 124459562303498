import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { catchError, map, Observable, of } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private jwtHelper: JwtHelperService, private authService: AuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const token = sessionStorage.getItem("accessToken");
        if (token && !this.jwtHelper.isTokenExpired(token)) {
            return true;
        }
        const refreshToken = sessionStorage.getItem("refreshToken");
        if (!token || !refreshToken) {
            return false;
        }
        return this.tryRefreshingTokens(token, refreshToken);
    }
    
    private tryRefreshingTokens(token: string, refreshToken: string) {
        const credentials = { accessToken: token, refreshToken: refreshToken };
        return this.authService.refreshToken(credentials).pipe(
            // Refreshing Token
            map((res: any) => {
                sessionStorage.setItem("accessToken", res.token);
                sessionStorage.setItem("refreshToken", res.refreshToken);
                return true;
            }),
            catchError(err => {
                sessionStorage.removeItem("accessToken");
                sessionStorage.removeItem("refreshToken");
                this.router.navigate(['/session-timeout'])
                return of(false);
            })
        );
    }
}