export enum Steps {
    'SAPI registration' = 0,
    'Personal Info V1' = 1,
    'WP Signature' = 2,
    'OTP' = 5,
    'DC Quote UI' = 6,
    'DC explained - content page' = 7,
    'Security Questions Set 1' = 9,
    'Security Questions Set 2' = 10,
    'Personal Info V2' = 11,
    'SA ID number' = 12,
    'DBC Password' = 13,
    'DBC Password and Login' = 18,
    '7 Step Security questions' = 19,
    'Referral landing page' = 20
}