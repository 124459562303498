export class Helper {

    //#region LocalStorageWithExpireTime
    SetLocalStorage(key: string, value: string, hours: number) {
        let now = new Date();
        const item = {
            value: value,
            expiry: now.getTime() + (hours * 3600 * 1000),
        }
        localStorage.setItem(key, JSON.stringify(item));
    }

    GetLocalStorage(key: string) {
        const itemStr = localStorage.getItem(key);
        // if the item doesn't exist, return null
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value
    }

    RemoveLocalStorage(key: string) {
        localStorage.removeItem(key);
    }
    //#endregion LocalStorageWithExpireTime

    CountDownTimer(duration: number, callback: any) {
        var timer = duration, minutes, seconds;
        var myInterval = setInterval(function () {
            minutes = parseInt((timer / 60).toString(), 10);
            seconds = parseInt((timer % 60).toString(), 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            if (seconds == '00') {
                console.log('Session Timeout after ', minutes + ":" + seconds);
            }

            if (--timer < 0) {
                timer = duration;
                clearInterval(myInterval);
                if (callback) {
                    callback();
                }
                console.log('finished');
            }
        }, 1000);
    }
}
